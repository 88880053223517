<template>
<!-- 同步大班课 -->
  <div class="addclass">
    <div class="content">
      <div class="cont-mid">
        <el-form ref="form" :rules="rules" :model="form" label-width="200px">
          <el-form-item label="课节名称：" prop="name">
            <el-input
              v-model="form.name"
              maxlength="100"
              show-word-limit
              style="width:555px"
              :disabled="isDisable"
            ></el-input>
          </el-form-item>
          <!-- 大班普通课 -->
          <el-form-item label="上课时间：" required>
            <dateTimePikcer @dateTimePicker="handleTimeChange" 
                            :endTime="form.lessonsEndTime"
                            :beginTime="form.lessonsBeginTime"
                            :isDisabled="isDisable">
            </dateTimePikcer>
            <el-tooltip class="item" effect="dark" content="请选择当前时刻起，一小时后的时间点" placement="top" v-if="$route.query.courseType == 3" >
              <i class="el-icon-question csp f20" ></i>
            </el-tooltip>
          </el-form-item>

          <el-row :gutter="20">
            <el-col :span="13">
              <el-form-item label="授课老师：" :required="true" >
                <teacherSelect :requestType="'teacher'" 
                               :role.sync="form.teacherRole"
                               :selected.sync="form.teacher.id"
                               :courseId="Number(form.courseProjectId)"
                               @teacherSelect="handleTeacherChange" 
                               :isDisabled="isDisable"/>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <teacherSelect :requestType="'assistant'" 
                              :role.sync="form.assistantRole"
                              :selected.sync="form.assistant.id"
                              :courseId="Number(form.courseProjectId)"
                              @teacherSelect="handleTeacherChange" 
                              :isDisabled="isDisable"/>
            </el-col>
          </el-row>

          <el-form-item label="PC端直播面板：" prop="isVideoMain" :required="true">
            <el-radio-group v-model="form.isVideoMain" :disabled="isDisable" >
              <el-radio :label="1">以视频为主</el-radio>
              <el-radio :label="2">以PPT为主</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="手机端直播面板：" prop="mobileIsVideoMain" :required="true">
            <el-radio-group v-model="form.mobileIsVideoMain" :disabled="isDisable">
              <el-radio :label="1">以视频为主</el-radio>
              <el-radio :label="2">以PPT为主</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="直播课件："  :required="$route.query.courseType != 3" > 
            <uploadFile @uploadFile="handleFileChange" :name="'liveIdentifiers'" :list="form.liveFileNames" :isDisabled="isDisable" :sourceType=2 />
          </el-form-item>

          <el-form-item label="课节附件：">
            <uploadFile @uploadFile="handleFileChange" :name="'lessonIdentifiers'" :list="form.lessonFileNames" :isDisabled="isDisable" :sourceType=2 />
          </el-form-item>

          <el-form-item label="点播视频：" :required="true" v-if="$route.query.courseType == 3">
            <uploadFile @uploadFile="handleFileChange" :name="'vodIdentifier'" :list="form.vodName" :isDisabled="isDisable" :sourceType=1 />
          </el-form-item>

          <el-form-item label="回放视频：">
            <uploadFile @uploadFile="handleFileChange" :name="'videoIdentifiers'" :list="form.videoNames" :tips="{image:false,url:true}" :sourceType=1 :isDisable="videoDisable"/>
          </el-form-item>

          <el-form-item class="el-form-item-btns">
            <el-button @click="$router.push(`/lesson/${form.courseProjectId}?breadNumber=4`)">取消</el-button>
            <el-button type="primary" @click="onSubmit">确定</el-button>
          </el-form-item>

          <br />
          <br />
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "newcourse",
  data() {
    return {
      videoDisable:false,
      isDisable:false,
      form: {
        isVideoMain:2,
        mobileIsVideoMain:1,
        teacher:{id:0},
        assistant:{id:0},
      },
      rules: {
        name: [{ required: true, message: "请输入课节名称", trigger: "blur" }],
        video: [{ required: true }],
        isVideoMain: [{ required: true, message: "请选择PC端面板", trigger: "change" }],
        mobileIsVideoMain: [{ required: true, message: "请选择手机端面板", trigger: "change" }],
      },
    };
  },
  computed: {},
  components: {},
  methods: {
    handleTimeChange(data){
      // console.log(data);
      this.form.lessonsBeginTime = data.bTime
      this.form.lessonsEndTime = data.eTime
      // console.log(this.form);
    },
    handleTeacherChange(data){
      // console.log(data);
      let type = data.type === 'teacher' ? true : (data.type === 'assistant'? false: '')
      if(type) {
        this.form.teacherId = data.teacherId
        this.form.teacherRole = data.role
      } else {
        this.form.assistantId = data.teacherId
        this.form.assistantRole = data.role
      }
      // console.log(this.form);
    },
    handleFileChange(data){
      // console.log(data);
      this.form[data.name] = data.fileMD5List;
      if(data.name === 'vodIdentifier') this.form.vodName = undefined
      // console.log(this.form)
    },
    onSubmit() {
      this.$refs['form'].validate((valid)=>{
        if(valid) {
          // 验证伪直播点播视频是否填写
          // 课程类型为伪直播 || 有vodName直接去请求
          if(this.$route.query.courseType != 3 || this.form.vodName)  {
            this.commitForm();
            return
          }
          if(!this.form.vodIdentifier ||this.form.vodIdentifier.length == 0) {
            this.$message({
              showClose: true,
              message: '未添加点播视频！',
              type: 'error'
            });
            return;
          } 
          this.commitForm();
        }
      })
    },
    async commitForm(){
      this.form.lessonsName = this.form.name
      this.form.lessonsPattern = this.$route.query.lessonsPattern
      this.form.teacherId = this.form.teacherId || this.form.teacher.id || ''
      this.form.assistantId = this.form.assistantId || this.form.assistant.id || ''
      // this.form.vodIdentifier = this.form.vodIdentifier ? this.form.vodIdentifier[0] : (this.form.vodName ? this.form.vodName[0].identifier : '');
      if(typeof(this.form.vodIdentifier) !== 'string')  {
        this.form.vodIdentifier = this.form.vodIdentifier ? this.form.vodIdentifier[0] : '';
      }
      // console.log(this.form);

      let resData = await this.$Api.Course.editLesson(this.form);
      if (resData.data.code === 200) {
        this.$notify({
          title: "成功",
          message: "编辑成功",
          type: "success"
        });
        setTimeout(() => {
          this.$router.push(`/lesson/${this.form.courseProjectId}?breadNumber=4`);
        }, 500);
      } else {
        this.$notify.error({
          title: "错误",
          // message: resData.data.msg
          message: this.errorMessage(resData.data.msg)
        });
      }
    },
    errorMessage(msg){
      if(msg == '参数room_id不能为空') {
        return '点播视频上传未完成,暂时无法修改'
      } else {
        return msg
      }
    },
    // 获取课节信息
    async getLessonInfo() {
      let id = this.$route.params.id;
      let resData = await this.$Api.Course.getLesson(id);
      console.log(resData);
      if (resData) {
        this.form = resData.data
        if(this.form.vodName) {
          let arr = [];
          arr.push(this.form.vodName)
          this.form.vodName = arr
        }
        // this.isDisable = this.dayjs().unix() > this.form.lessonsBeginTime ? (this.$route.query.courseType != 3 ? true : false ) : false
        if(this.$route.query.courseType == 3 && !this.form.roomId) {
          this.videoDisable = true
        } else {
          this.videoDisable = false
        }
        this.isDisable = this.dayjs().unix() > this.form.lessonsBeginTime ? true : false
        if(!this.form.assistant) this.form.assistant = {id:0}
        // console.log(this.form);
      }
    },
  },
  mounted() {
    this.getLessonInfo()
  },
  activated() {
     this.getLessonInfo()
  },
  deactivated() {
    this.form = {
      isVideoMain:2,
      mobileIsVideoMain:1,
      teacher:{id:0},
      assistant:{id:0},
    }
     this.$refs["form"].resetFields();
  },
  created() {}
};
</script>

<style lang="less"></style>
